<template>
    <div class="wrapper">
        <material-card :title="'Users'">
            <div class="text-right mb-6">
                <v-btn
                        color="blue-grey darken-4"
                        @click="edit_user({})"
                >
                    <v-icon>
                        mdi-plus
                    </v-icon> New User
                </v-btn>
            </div>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Email</th>
                        <th class="text-left">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in users" >
                        <td>{{ user.first_name }} {{ user.last_name }}</td>
                        <td>{{ user.email }}</td>
                        <td class="text-center">
                            <v-btn
                                    color="blue-grey darken-4"
                                    @click="edit_user(user)"
                                    text
                                    fab
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn
                                    color="blue-grey darken-4"
                                    @click="update_state(1, user)"
                                    text
                                    fab
                                    v-show="user.profile.state !== 1"
                            >
                                <v-icon>
                                    mdi-account-plus
                                </v-icon>
                            </v-btn>
                            <v-btn
                                    color="blue-grey darken-4"
                                    @click="update_state(2, user)"
                                    text
                                    fab
                                    v-show="user.profile.state === 1"
                            >
                                <v-icon>
                                    mdi-account-off
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </material-card>

        <v-dialog v-model="user_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary">

              <v-toolbar-title>Settings</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                  <v-btn icon dark @click="user_dialog = false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                 <v-container>
                     <v-row>
                         <v-col cols="12" sm="6" md="4">
                             <v-text-field
                                     v-model="user.first_name"
                                     :counter="200"
                                     label="First Name"
                                     required
                                     :error-messages="checkFirstName"
                                     @input="$v.user.first_name.$touch()"
                                     @blur="$v.user.first_name.$touch()"

                             ></v-text-field>
                         </v-col>
                         <v-col cols="12" sm="6" md="4" lg="4">
                             <v-text-field
                                     v-model="user.last_name"
                                     :counter="200"
                                     label="Last Name"
                                     required
                                     :error-messages="checkLastName"
                                     @input="$v.user.last_name.$touch()"
                                     @blur="$v.user.last_name.$touch()"

                             ></v-text-field>
                         </v-col>
                         <v-col cols="12" sm="6" md="4" lg="4">
                             <v-text-field
                                     v-model="user.email"
                                     :counter="200"
                                     label="Email Address"
                                     required
                                     :error-messages="checkEmail"
                                     @input="$v.user.email.$touch()"
                                     @blur="$v.user.email.$touch()"
                             ></v-text-field>
                         </v-col>
                         <v-col cols="12" sm="12" md="6" lg="6">
                             <v-text-field
                                     v-if="user.id === 0"
                                     v-model="new_user.password"
                                     :counter="45"
                                     required
                                     :error-messages="checkPassword"
                                     @input="$v.new_user.password.$touch()"
                                     @blur="$v.new_user.password.$touch()"
                                     label="Password"

                             ></v-text-field>
                         </v-col>
                         <v-col cols="12" sm="12" md="6" lg="6">
                             <v-text-field
                                     v-if="user.id === 0"
                                     v-model="new_user.confirm_password"
                                     :counter="45"
                                     label="Confirm Password"
                                     required
                                     :error-messages="checkPasswordConfirm"
                                     @input="$v.new_user.confirm_password.$touch()"
                                     @blur="$v.new_user.confirm_password.$touch()"

                             ></v-text-field>
                         </v-col>
                         <v-col cols="12" sm="12" md="12" lg="12">
                             <v-textarea
                                     label="Address"
                                     v-model="user.profile.address"
                                     required
                                     :error-messages="checkAddress"
                                     @input="$v.user.profile.address.$touch()"
                                     @blur="$v.user.profile.address.$touch()"
                                     class=""></v-textarea>
                         </v-col>
                         <v-col cols="12" sm="12" md="4" lg="4">
                             <v-text-field
                                     v-model="user.profile.region"
                                     :counter="200"
                                     label="City"
                                     required
                                     :error-messages="checkRegion"
                                     @input="$v.user.profile.region.$touch()"
                                     @blur="$v.user.profile.region.$touch()"

                             ></v-text-field>
                         </v-col>
                         <v-col cols="12" sm="12" md="4" lg="4">
                             <v-text-field
                                     v-model="user.profile.zip_code"
                                     :counter="200"
                                     label="Zip Code"
                                     required
                                     :error-messages="checkZip"
                                     @input="$v.user.profile.zip_code.$touch()"
                                     @blur="$v.user.profile.zip_code.$touch()"

                             ></v-text-field>
                         </v-col>
                         <v-col cols="12" sm="12" md="4" lg="4">
                             <v-select
                                     v-model="user.profile.country"
                                     :items="countries"
                                     item-value="id"
                                     item-text="name"
                                     label="Country"
                                     required
                                     :error-messages="checkCountry"
                                     @input="$v.user.profile.country.$touch()"
                                     @blur="$v.user.profile.country.$touch()"
                             ></v-select>
                         </v-col>

                         <v-col cols="12" sm="12" md="4" lg="4">
                             <v-text-field
                                     v-model="user.profile.phone_extension"
                                     :counter="12"
                                     label="Extension"

                             ></v-text-field>
                         </v-col>
                         <v-col cols="12" sm="12" md="4" lg="4">
                             <v-text-field
                                     v-model="user.profile.phone"
                                     :counter="25"
                                     label="Phone"
                                     required
                                     :error-messages="checkPhone"
                                     @input="$v.user.profile.phone.$touch()"
                                     @blur="$v.user.profile.phone.$touch()"

                             ></v-text-field>
                         </v-col>
                         <v-col cols="12" sm="12" md="4" lg="4">
                             <v-text-field
                                     v-model="user.profile.mobile"
                                     :counter="23"
                                     label="Mobile N"
                                     required
                                     :error-messages="checkMobile"
                                     @input="$v.user.profile.mobile.$touch()"
                                     @blur="$v.user.profile.mobile.$touch()"

                             ></v-text-field>
                         </v-col>

                         <v-col cols="12" sm="12" md="4" lg="4">
                             <v-text-field
                                     v-model="user.profile.website"
                                     :counter="200"
                                     label="Website"
                                     required
                                     :error-messages="checkWebsite"
                                     @input="$v.user.profile.website.$touch()"
                                     @blur="$v.user.profile.website.$touch()"

                             ></v-text-field>
                         </v-col>

                     </v-row>
                 </v-container>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn
                        outlined
                        block
                      color="blue-grey darken-4"
                      @click="save_user(user)"
                    >
                      Save
                    </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, maxLength, minLength, email, sameAs} from 'vuelidate/lib/validators'

    export default {
        components: {},
        mixins: [validationMixin],

        validations: {
            new_user: {
                password: {
                    required,
                    minLength:minLength(6),
                    maxLength:maxLength(45)
                },
                confirm_password: {
                    required,
                    sameAsPassword: sameAs('password')
                },
            },
            user: {
                first_name: {
                    required
                },
                last_name: {
                    required
                },
                email: {
                    required,
                    email
                },
                profile: {
                    address: {
                        required,
                        maxLength:maxLength(200)
                    },
                    region: {
                        required
                    },
                    zip_code: {
                        required,
                    },
                    country: {
                        required
                    },
                    phone: {
                        required,
                        minLength:minLength(6)
                    },
                    mobile: {
                        required,
                        minLength: minLength(11)
                    },
                    website: {
                        required
                    },
                }
            }
        },
        data() {
            return {
                users: [],
                default_user: {
                    id:0,
                    profile: {
                        website: "https://wellstartrading.com"
                    }
                },
                new_user: {
                    password: '',
                    confirm_password: ''
                },
                user: {
                    id:0,
                    profile: {}
                },
                countries: [],
                user_dialog: false
            }
        },
        computed: {
            checkPassword() {
                const errors = [];
                if (!this.$v.new_user.password.$dirty) return errors;
                !this.$v.new_user.password.maxLength && errors.push('new_user.password must be at most 45 characters long');
                !this.$v.new_user.password.minLength && errors.push('new_user.password must be at least 6 characters long');
                !this.$v.new_user.password.required && errors.push('new_user.password is required.');
                return errors;
            },
            checkPasswordConfirm() {
                const errors = [];
                if (!this.$v.new_user.confirm_password.$dirty) return errors;
                !this.$v.new_user.confirm_password.required && errors.push('new_user.confirm_password is required.');
                !this.$v.new_user.confirm_password.sameAsPassword && errors.push('Confirm password does not match password');
                return errors;
            },
            checkFirstName() {
                const errors = [];
                if (!this.$v.user.first_name.$dirty) return errors;
                !this.$v.user.first_name.required && errors.push('user.first_name is required.');
                return errors;
            },
            checkLastName() {
                const errors = [];
                if (!this.$v.user.last_name.$dirty) return errors;
                !this.$v.user.last_name.required && errors.push('user.last_name is required.');
                return errors;
            },
            checkEmail() {
                const errors = [];
                if (!this.$v.user.email.$dirty) return errors;
                !this.$v.user.email.email && errors.push('Email must be a valid email address');
                !this.$v.user.email.required && errors.push('user.email is required.');
                return errors;
            },
            checkAddress() {
                const errors = [];
                if (!this.$v.user.profile.address.$dirty) return errors;
                !this.$v.user.profile.address.maxLength && errors.push('user.profile.address must be at most 200 characters long');
                !this.$v.user.profile.address.required && errors.push('user.profile.address is required.');
                return errors;
            },
            checkRegion() {
                const errors = [];
                if (!this.$v.user.profile.region.$dirty) return errors;
                !this.$v.user.profile.region.required && errors.push('user.profile.region is required.');
                return errors;
            },
            checkZip() {
                const errors = [];
                if (!this.$v.user.profile.zip_code.$dirty) return errors;
                !this.$v.user.profile.zip_code.required && errors.push('user.profile.zip_code is required.');
                return errors;
            },
            checkCountry() {
                const errors = [];
                if (!this.$v.user.profile.country.$dirty) return errors;
                !this.$v.user.profile.country.required && errors.push('user.profile.country is required.');
                return errors;
            },
            checkPhone() {
                const errors = [];
                if (!this.$v.user.profile.phone.$dirty) return errors;
                !this.$v.user.profile.phone.minLength && errors.push('user.profile.phone must be at least 6 characters long');
                !this.$v.user.profile.phone.required && errors.push('user.profile.phone is required.');
                return errors;
            },
            checkMobile() {
                const errors = [];
                if (!this.$v.user.profile.mobile.$dirty) return errors;
                !this.$v.user.profile.mobile.minLength && errors.push('user.profile.phone must be at least 11 characters long');
                !this.$v.user.profile.mobile.required && errors.push('user.profile.mobile is required.');
                return errors;
            },
            checkWebsite() {
                const errors = [];
                if (!this.$v.user.profile.website.$dirty) return errors;
                !this.$v.user.profile.website.required && errors.push('user.profile.website is required.');
                return errors;
            },
        },
        created() {
            this.load_countries();
            this.load_users();
        },
        methods: {
            update_state(state, user)
            {
                let profile = user.profile;
                this.$hpatch(
                    this.$http.defaults.baseURL + '/factories/user/' + user.id + '/', {
                        state:state,
                        profile: profile
                    }).then((response) => {
                    if (typeof response.data !== 'undefined') {
                        this.user_dialog = false;
                        this.$notify({
                            title: 'Success:',
                            text: 'Successfully changed state',
                            group: 'notifications',
                            position: 'top right'
                        });
                        user.state = state;
                        this.load_users();
                    }
                });
            },
            load_countries()
            {
              this.$hget(
                  this.$http.defaults.baseURL + '/tools/countries/').then((response) => {
                      if(typeof response.data !== 'undefined')
                      {
                          this.countries = response.data;
                      }
                  });
            },
            load_users() {
                this.$hget(
                    this.$http.defaults.baseURL + '/factories/user/').then((response) => {
                    if (typeof response.data !== 'undefined') {
                        this.users = response.data.results;
                    }
                });
            },
            save_user(user) {
                user.username = user.email;
                this.$v.user.$touch();
                console.log(this.$v.user);
                if (this.$v.user.$invalid) {
                    this.$notify({
                        title: 'Error:',
                        text: 'Existing: Please Fill Out The Form Correctly',
                        group: 'notifications',
                        position: 'top right'
                    });
                    return false;
                }
                if (user.id > 0) {
                    this.$hpatch(
                        this.$http.defaults.baseURL + '/factories/user/' + user.id + '/', user).then((response) => {
                        if (typeof response.data !== 'undefined') {
                            this.user_dialog = false;
                            this.$notify({
                                title: 'Success:',
                                text: 'Successfully updated user',
                                group: 'notifications',
                                position: 'top right'
                            });
                        }
                    });
                } else {
                    user.password = this.new_user.password;
                    this.$v.new_user.$touch();
                    if (this.$v.new_user.$invalid) {
                        console.log(this.$v.new_user);
                        this.$notify({
                            title: 'Error:',
                            text: 'New User Please Fill Out The Form Correctly',
                            group: 'notifications',
                            position: 'top right'
                        });
                        return false;
                    }

                    this.check_username(user.username).then((response) => {
                        if(response.data.count < 1)
                        {
                            this.$hpost(this.$http.defaults.baseURL + '/factories/user/', user).then((response) => {
                                if(typeof response.data !== 'undefined')
                                {
                                    this.$notify({
                                        title: 'Success:',
                                        text: 'Successfully created user',
                                        group: 'notifications',
                                        position: 'top right'
                                    });
                                    this.load_users();
                                    this.user_dialog = false;
                                }
                            });
                        } else {
                            this.$notify({
                                title: 'Error:',
                                text: 'User email already exists in the system.',
                                group: 'notifications',
                                position: 'top right'
                            });
                        }
                    });
                }
            },
            async check_username(username) {
                return this.$hget(
                    this.$http.defaults.baseURL + '/factories/users/?username=' +username )
            },
            edit_user(user)
            {
                if(typeof user.id === 'undefined')
                {
                    this.user = this.default_user;
                } else {
                    this.user = user;
                }
                this.user_dialog = true;
            }
        }
    }
</script>
